import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _239c2240 = () => interopDefault(import('../client/pages/cart/index.vue' /* webpackChunkName: "" */))
const _9fdf6160 = () => interopDefault(import('../client/pages/products/no-minimum/_.vue' /* webpackChunkName: "" */))
const _6cfd5fb8 = () => interopDefault(import('../client/pages/products/closeout/_.vue' /* webpackChunkName: "" */))
const _01707ae3 = () => interopDefault(import('../client/pages/account/index.vue' /* webpackChunkName: "pages/account/index" */))
const _390b0d80 = () => interopDefault(import('../client/pages/cartitemsadded/index.vue' /* webpackChunkName: "pages/cartitemsadded/index" */))
const _36f7896b = () => interopDefault(import('../client/pages/changepassword/index.vue' /* webpackChunkName: "pages/changepassword/index" */))
const _1ea14f5c = () => interopDefault(import('../client/pages/customer-artwork-proof/index.vue' /* webpackChunkName: "pages/customer-artwork-proof/index" */))
const _6906ad2b = () => interopDefault(import('../client/pages/designs/index.vue' /* webpackChunkName: "pages/designs/index" */))
const _ac02df08 = () => interopDefault(import('../client/pages/facets/index.vue' /* webpackChunkName: "pages/facets/index" */))
const _fadcb866 = () => interopDefault(import('../client/pages/favorites/index.vue' /* webpackChunkName: "pages/favorites/index" */))
const _1314821e = () => interopDefault(import('../client/pages/forgotpassword/index.vue' /* webpackChunkName: "pages/forgotpassword/index" */))
const _4aa3cd0e = () => interopDefault(import('../client/pages/health.vue' /* webpackChunkName: "pages/health" */))
const _76353c3e = () => interopDefault(import('../client/pages/not-found/index.vue' /* webpackChunkName: "pages/not-found/index" */))
const _6a59c3fa = () => interopDefault(import('../client/pages/proof/index.vue' /* webpackChunkName: "pages/proof/index" */))
const _b6e68b0c = () => interopDefault(import('../client/pages/proofitemsadded/index.vue' /* webpackChunkName: "pages/proofitemsadded/index" */))
const _9e75389c = () => interopDefault(import('../client/pages/quoteitemsadded/index.vue' /* webpackChunkName: "pages/quoteitemsadded/index" */))
const _90111dc0 = () => interopDefault(import('../client/pages/resetpassword/index.vue' /* webpackChunkName: "pages/resetpassword/index" */))
const _851724ec = () => interopDefault(import('../client/pages/sampleitemsadded/index.vue' /* webpackChunkName: "pages/sampleitemsadded/index" */))
const _cb8a21fc = () => interopDefault(import('../client/pages/signin/index.vue' /* webpackChunkName: "pages/signin/index" */))
const _1b268827 = () => interopDefault(import('../client/pages/signout/index.vue' /* webpackChunkName: "pages/signout/index" */))
const _c72f14c6 = () => interopDefault(import('../client/pages/suppliers/index.vue' /* webpackChunkName: "pages/suppliers/index" */))
const _4701c91d = () => interopDefault(import('../client/pages/testy/index.vue' /* webpackChunkName: "pages/testy/index" */))
const _18bd018b = () => interopDefault(import('../client/pages/version.vue' /* webpackChunkName: "pages/version" */))
const _4363d822 = () => interopDefault(import('../client/pages/version-app/index.vue' /* webpackChunkName: "pages/version-app/index" */))
const _f700647c = () => interopDefault(import('../client/pages/cat/GeneralProductView.vue' /* webpackChunkName: "pages/cat/GeneralProductView" */))
const _a30b1c78 = () => interopDefault(import('../client/pages/orders/products/index.vue' /* webpackChunkName: "pages/orders/products/index" */))
const _d035c468 = () => interopDefault(import('../client/pages/products/utils.js' /* webpackChunkName: "pages/products/utils" */))
const _9653d512 = () => interopDefault(import('../client/pages/products/search/mixin.js' /* webpackChunkName: "pages/products/search/mixin" */))
const _0e2d43ce = () => interopDefault(import('../client/pages/products/search/search.js' /* webpackChunkName: "pages/products/search/search" */))
const _f2e65972 = () => interopDefault(import('../client/pages/sso/facebook/delete.vue' /* webpackChunkName: "pages/sso/facebook/delete" */))
const _b169f466 = () => interopDefault(import('../client/pages/sso/punch-out/_source.vue' /* webpackChunkName: "pages/sso/punch-out/_source" */))
const _c1a837d6 = () => interopDefault(import('../client/pages/review/order/_orderId/product/_productId.vue' /* webpackChunkName: "pages/review/order/_orderId/product/_productId" */))
const _2f979914 = () => interopDefault(import('../client/pages/products/search/_.vue' /* webpackChunkName: "pages/products/search/_" */))
const _0b8171d6 = () => interopDefault(import('../client/pages/blog/cat/_.vue' /* webpackChunkName: "pages/blog/cat/_" */))
const _65f30468 = () => interopDefault(import('../client/pages/approval/_orderId/index.vue' /* webpackChunkName: "pages/approval/_orderId/index" */))
const _f441e902 = () => interopDefault(import('../client/pages/artproof/_productSlug/index.vue' /* webpackChunkName: "pages/artproof/_productSlug/index" */))
const _5eeef7f8 = () => interopDefault(import('../client/pages/authors/_authorName/index.vue' /* webpackChunkName: "pages/authors/_authorName/index" */))
const _64ab3c08 = () => interopDefault(import('../client/pages/components/_componentId/index.vue' /* webpackChunkName: "pages/components/_componentId/index" */))
const _7c8340f7 = () => interopDefault(import('../client/pages/configureproduct/_productSlug/index.vue' /* webpackChunkName: "pages/configureproduct/_productSlug/index" */))
const _89694772 = () => interopDefault(import('../client/pages/customer-artwork-proof/_id/index.vue' /* webpackChunkName: "pages/customer-artwork-proof/_id/index" */))
const _2aa83a15 = () => interopDefault(import('../client/pages/designer/_productSlug/index.vue' /* webpackChunkName: "pages/designer/_productSlug/index" */))
const _e86247b4 = () => interopDefault(import('../client/pages/designs/_slug/index.vue' /* webpackChunkName: "pages/designs/_slug/index" */))
const _4a2d8292 = () => interopDefault(import('../client/pages/facets/_slug/index.vue' /* webpackChunkName: "pages/facets/_slug/index" */))
const _7497ed42 = () => interopDefault(import('../client/pages/largequote/_productSlug/index.vue' /* webpackChunkName: "pages/largequote/_productSlug/index" */))
const _975b8a20 = () => interopDefault(import('../client/pages/orders/_id/index.vue' /* webpackChunkName: "pages/orders/_id/index" */))
const _1d426ebc = () => interopDefault(import('../client/pages/payments/_orderId/index.vue' /* webpackChunkName: "pages/payments/_orderId/index" */))
const _558d6a15 = () => interopDefault(import('../client/pages/presentation/_orderId/index.vue' /* webpackChunkName: "pages/presentation/_orderId/index" */))
const _58d5ad0c = () => interopDefault(import('../client/pages/presentations/_id/index.vue' /* webpackChunkName: "pages/presentations/_id/index" */))
const _0537d8e2 = () => interopDefault(import('../client/pages/printinvoice/_orderId/index.vue' /* webpackChunkName: "pages/printinvoice/_orderId/index" */))
const _26f0113f = () => interopDefault(import('../client/pages/products/_slug/index.vue' /* webpackChunkName: "pages/products/_slug/index" */))
const _53475c36 = () => interopDefault(import('../client/pages/proof/_id/index.vue' /* webpackChunkName: "pages/proof/_id/index" */))
const _17ad5511 = () => interopDefault(import('../client/pages/quickquote/_productSlug/index.vue' /* webpackChunkName: "pages/quickquote/_productSlug/index" */))
const _c40b7ac8 = () => interopDefault(import('../client/pages/reorder/_id/index.vue' /* webpackChunkName: "pages/reorder/_id/index" */))
const _11824194 = () => interopDefault(import('../client/pages/sample/_productSlug/index.vue' /* webpackChunkName: "pages/sample/_productSlug/index" */))
const _15fcb3d3 = () => interopDefault(import('../client/pages/sso/_service.vue' /* webpackChunkName: "pages/sso/_service" */))
const _48a6c93c = () => interopDefault(import('../client/pages/cat/_slug/brand.vue' /* webpackChunkName: "pages/cat/_slug/brand" */))
const _45f416b1 = () => interopDefault(import('../client/pages/orders/_id/track-shipment/index.vue' /* webpackChunkName: "pages/orders/_id/track-shipment/index" */))
const _96b6eb8e = () => interopDefault(import('../client/pages/printinvoice/_orderId/short.vue' /* webpackChunkName: "pages/printinvoice/_orderId/short" */))
const _6c2baec8 = () => interopDefault(import('../client/pages/products/_slug/reviews.vue' /* webpackChunkName: "pages/products/_slug/reviews" */))
const _496cc634 = () => interopDefault(import('../client/pages/facets/_slug/_facet/index.vue' /* webpackChunkName: "pages/facets/_slug/_facet/index" */))
const _4aa82119 = () => interopDefault(import('../client/pages/supplier/_psCode/_.vue' /* webpackChunkName: "pages/supplier/_psCode/_" */))
const _3d042894 = () => interopDefault(import('../client/pages/cat/_slug/_.vue' /* webpackChunkName: "pages/cat/_slug/_" */))
const _440839d2 = () => interopDefault(import('../client/pages/_.vue' /* webpackChunkName: "pages/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/box/cart",
    component: _239c2240,
    name: "box/cart"
  }, {
    path: "/quote/cart",
    component: _239c2240,
    name: "quote/cart"
  }, {
    path: "/sample/cart",
    component: _239c2240,
    name: "sample/cart"
  }, {
    path: "/proof/cart",
    component: _239c2240,
    name: "proof/cart"
  }, {
    path: "/boxcart",
    component: _239c2240,
    name: "boxcart"
  }, {
    path: "/quotecart",
    component: _239c2240,
    name: "quotecart"
  }, {
    path: "/samplecart",
    component: _239c2240,
    name: "samplecart"
  }, {
    path: "/proofcart",
    component: _239c2240,
    name: "proofcart"
  }, {
    path: "/products/no-minimum",
    component: _9fdf6160,
    name: "products-no-minimum"
  }, {
    path: "/products/closeout",
    component: _6cfd5fb8,
    name: "products-closeout"
  }, {
    path: "/account",
    component: _01707ae3,
    name: "account"
  }, {
    path: "/cart",
    component: _239c2240,
    name: "cart"
  }, {
    path: "/cartitemsadded",
    component: _390b0d80,
    name: "cartitemsadded"
  }, {
    path: "/changepassword",
    component: _36f7896b,
    name: "changepassword"
  }, {
    path: "/customer-artwork-proof",
    component: _1ea14f5c,
    name: "customer-artwork-proof"
  }, {
    path: "/designs",
    component: _6906ad2b,
    name: "designs"
  }, {
    path: "/facets",
    component: _ac02df08,
    name: "facets"
  }, {
    path: "/favorites",
    component: _fadcb866,
    name: "favorites"
  }, {
    path: "/forgotpassword",
    component: _1314821e,
    name: "forgotpassword"
  }, {
    path: "/health",
    component: _4aa3cd0e,
    name: "health"
  }, {
    path: "/not-found",
    component: _76353c3e,
    name: "not-found"
  }, {
    path: "/proof",
    component: _6a59c3fa,
    name: "proof"
  }, {
    path: "/proofitemsadded",
    component: _b6e68b0c,
    name: "proofitemsadded"
  }, {
    path: "/quoteitemsadded",
    component: _9e75389c,
    name: "quoteitemsadded"
  }, {
    path: "/resetpassword",
    component: _90111dc0,
    name: "resetpassword"
  }, {
    path: "/sampleitemsadded",
    component: _851724ec,
    name: "sampleitemsadded"
  }, {
    path: "/signin",
    component: _cb8a21fc,
    name: "signin"
  }, {
    path: "/signout",
    component: _1b268827,
    name: "signout"
  }, {
    path: "/suppliers",
    component: _c72f14c6,
    name: "suppliers"
  }, {
    path: "/testy",
    component: _4701c91d,
    name: "testy"
  }, {
    path: "/version",
    component: _18bd018b,
    name: "version"
  }, {
    path: "/version-app",
    component: _4363d822,
    name: "version-app"
  }, {
    path: "/cat/GeneralProductView",
    component: _f700647c,
    name: "cat-GeneralProductView"
  }, {
    path: "/orders/products",
    component: _a30b1c78,
    name: "orders-products"
  }, {
    path: "/products/utils",
    component: _d035c468,
    name: "products-utils"
  }, {
    path: "/products/search/mixin",
    component: _9653d512,
    name: "products-search-mixin"
  }, {
    path: "/products/search/search",
    component: _0e2d43ce,
    name: "products-search-search"
  }, {
    path: "/sso/facebook/delete",
    component: _f2e65972,
    name: "sso-facebook-delete"
  }, {
    path: "/sso/punch-out/:source?",
    component: _b169f466,
    name: "sso-punch-out-source"
  }, {
    path: "/review/order/:orderId?/product/:productId?",
    component: _c1a837d6,
    name: "review-order-orderId-product-productId"
  }, {
    path: "/products/search/*",
    component: _2f979914,
    name: "products-search-all"
  }, {
    path: "/products/no-minimum/*",
    component: _9fdf6160,
    name: "products-no-minimum-all"
  }, {
    path: "/products/closeout/*",
    component: _6cfd5fb8,
    name: "products-closeout-all"
  }, {
    path: "/blog/cat/*",
    component: _0b8171d6,
    name: "blog-cat-all"
  }, {
    path: "/approval/:orderId",
    component: _65f30468,
    name: "approval-orderId"
  }, {
    path: "/artproof/:productSlug",
    component: _f441e902,
    name: "artproof-productSlug"
  }, {
    path: "/authors/:authorName",
    component: _5eeef7f8,
    name: "authors-authorName"
  }, {
    path: "/components/:componentId",
    component: _64ab3c08,
    name: "components-componentId"
  }, {
    path: "/configureproduct/:productSlug",
    component: _7c8340f7,
    name: "configureproduct-productSlug"
  }, {
    path: "/customer-artwork-proof/:id",
    component: _89694772,
    name: "customer-artwork-proof-id"
  }, {
    path: "/designer/:productSlug",
    component: _2aa83a15,
    name: "designer-productSlug"
  }, {
    path: "/designs/:slug",
    component: _e86247b4,
    name: "designs-slug"
  }, {
    path: "/facets/:slug",
    component: _4a2d8292,
    name: "facets-slug"
  }, {
    path: "/largequote/:productSlug",
    component: _7497ed42,
    name: "largequote-productSlug"
  }, {
    path: "/orders/:id",
    component: _975b8a20,
    name: "orders-id"
  }, {
    path: "/payments/:orderId",
    component: _1d426ebc,
    name: "payments-orderId"
  }, {
    path: "/presentation/:orderId",
    component: _558d6a15,
    name: "presentation-orderId"
  }, {
    path: "/presentations/:id",
    component: _58d5ad0c,
    name: "presentations-id"
  }, {
    path: "/printinvoice/:orderId",
    component: _0537d8e2,
    name: "printinvoice-orderId"
  }, {
    path: "/products/:slug",
    component: _26f0113f,
    name: "products-slug"
  }, {
    path: "/proof/:id",
    component: _53475c36,
    name: "proof-id"
  }, {
    path: "/quickquote/:productSlug",
    component: _17ad5511,
    name: "quickquote-productSlug"
  }, {
    path: "/reorder/:id",
    component: _c40b7ac8,
    name: "reorder-id"
  }, {
    path: "/sample/:productSlug",
    component: _11824194,
    name: "sample-productSlug"
  }, {
    path: "/sso/:service?",
    component: _15fcb3d3,
    name: "sso-service"
  }, {
    path: "/cat/:slug?/brand",
    component: _48a6c93c,
    name: "cat-slug-brand"
  }, {
    path: "/orders/:id?/track-shipment",
    component: _45f416b1,
    name: "orders-id-track-shipment"
  }, {
    path: "/printinvoice/:orderId?/short",
    component: _96b6eb8e,
    name: "printinvoice-orderId-short"
  }, {
    path: "/products/:slug?/reviews",
    component: _6c2baec8,
    name: "products-slug-reviews"
  }, {
    path: "/facets/:slug/:facet",
    component: _496cc634,
    name: "facets-slug-facet"
  }, {
    path: "/supplier/:psCode?/*",
    component: _4aa82119,
    name: "supplier-psCode-all"
  }, {
    path: "/cat/:slug?/*",
    component: _3d042894,
    name: "cat-slug-all"
  }, {
    path: "/*",
    component: _440839d2,
    name: "all"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
