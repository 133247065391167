import { render, staticRenderFns } from "./LoadingPage.vue?vue&type=template&id=6d070c62&scoped=true&lang=html"
import script from "./LoadingPage.vue?vue&type=script&lang=js"
export * from "./LoadingPage.vue?vue&type=script&lang=js"
import style0 from "./LoadingPage.vue?vue&type=style&index=0&id=6d070c62&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6d070c62",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IEWarning: require('/codebuild/output/src906293329/src/client/components/IEWarning.vue').default})
